/******* screen-medium.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
#head {
  max-width: 521px;
  margin-top: 90px;
}
.section--header {
  padding-top: 100px;
}
.headercontent {
  float: left;
  width: 34.07455853vw;
  max-width: 521px;
  margin-left: 8%;
}
@media only screen and (min-width: 1529px) {
  .headercontent {
    margin-left: calc(50% - 642px);
  }
}
@media (min-width: 1024px) {
  .cb-layout1 .headercontent,
  .cb-layout2 .headercontent {
    width: 700px;
    max-width: 700px;
    margin-right: -100%;
    margin-left: calc(50% - 800px);
  }
}
@media (min-width: 1024px) and (max-width: 1700px) {
  .cb-layout1 .headercontent,
  .cb-layout2 .headercontent {
    margin-left: 50px;
    width: 370px;
    max-width: 370px;
  }
}
#expo {
  float: right;
  width: 55.05208333%;
  padding-bottom: 52.60416667%;
}
@media only screen and (min-width: 2396px) {
  #expo {
    width: 50%;
    padding-bottom: 47.76041667%;
  }
}
.cb-layout3 #expo {
  margin-right: 10.72596468%;
  margin-top: 125px;
  width: 193px;
  padding-bottom: 168px;
}
@media only screen and (min-width: 1529px) {
  .cb-layout3 #expo {
    margin-right: calc(50% - 600px);
  }
}
.area.main,
.area.base {
  width: 100%;
}
.area.main > .unit,
.area.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area.main .foot,
.area.base .foot {
  margin-right: 1.37614679%;
  margin-left: 1.37614679%;
  width: 97.24770642%;
}
.area.main .part,
.area.base .part {
  margin-right: 1.37614679%;
  margin-left: 1.37614679%;
  width: 97.24770642%;
}
.area.main .tiny,
.area.base .tiny {
  width: 47.24770642%;
}
.area.main > .slim,
.area.base > .slim {
  width: 50%;
}
.area.main > .slim .foot,
.area.base > .slim .foot,
.area.main > .slim .part,
.area.base > .slim .part {
  margin-right: 2.75229358%;
  margin-left: 2.75229358%;
}
.area.main > .slim .part,
.area.base > .slim .part {
  width: 94.49541284%;
}
.area.main > .slim .tiny,
.area.base > .slim .tiny {
  width: 44.49541284%;
}
.area.main,
.area.base {
  width: 101.86915888%;
  margin-left: -0.93457944%;
}
.area.main .unit.fold,
.area.base .unit.fold {
  width: 97.24770642%;
  margin-left: 1.37614679%;
  margin-right: 1.37614679%;
}
.area.main .unit.flat,
.area.base .unit.flat,
.area.main .unit.edge,
.area.base .unit.edge {
  width: 97.24770642%;
  margin-left: 1.37614679%;
  margin-right: 1.37614679%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.area.main .unit.flat .body,
.area.base .unit.flat .body,
.area.main .unit.edge .body,
.area.base .unit.edge .body {
  padding: 45px 60px;
}
.area.main .unit.flat .body:before,
.area.base .unit.flat .body:before,
.area.main .unit.edge .body:before,
.area.base .unit.edge .body:before {
  right: -34px;
  width: 66px;
  height: 115px;
}
.area.main .unit.flat .body:after,
.area.base .unit.flat .body:after,
.area.main .unit.edge .body:after,
.area.base .unit.edge .body:after {
  right: 17px;
  font-size: 30px;
  font-size: 3rem;
}
.cb-layout3 .area.main {
  margin-top: 45px;
}
.cb-layout1 .north {
  width: 101.86915888%;
  margin-left: -0.93457944%;
}
.cb-layout1 .north .unit {
  width: 48.16513761%;
  margin-left: 0.91743119%;
  margin-right: 0.91743119%;
}
.cb-layout2 .area.north,
.cb-layout3 .area.north {
  width: 101.86915888%;
  margin-left: -0.93457944%;
}
.cb-layout2 .area.north .unit.pure,
.cb-layout3 .area.north .unit.pure {
  width: 97.24770642%;
  margin-left: 1.37614679%;
  margin-right: 1.37614679%;
}
.cb-layout2 .area.north .unit.seam,
.cb-layout3 .area.north .unit.seam,
.cb-layout2 .area.north .unit.flat,
.cb-layout3 .area.north .unit.flat {
  width: 47.24770642%;
  margin-left: 1.37614679%;
  margin-right: 1.37614679%;
}
.area.south .part.tiny,
.area.farwest .part.tiny {
  width: 33%;
  padding: 0 12px;
}
.area.south .unit .body,
.area.farwest .unit .body {
  max-width: 848px;
}
.bae > .wide.form .tile .name,
.main > .wide.form .tile .name,
.bae > .wide a.capt,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.bae > .wide.form .tile .chop,
.main > .wide.form .tile .chop,
.bae > .wide.form .tile textarea,
.main > .wide.form .tile textarea,
.bae > .wide.form .tile input.text,
.main > .wide.form .tile input.text,
.bae > .wide.form .tile .ctrl,
.main > .wide.form .tile .ctrl,
.bae > .wide input.capt,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*# sourceMappingURL=./screen-medium.css.map */